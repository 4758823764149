import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  background: #000000;\n  color: #fff;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  list-style: none;\n  margin-bottom: 40px;\n\n  ",
        " {\n    margin-bottom: 0px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  margin-bottom: 8px;\n  line-height: 18px;\n  a {\n    font-size: 14px;\n  }\n  &:first-child {\n    color: ",
        ";\n    font-weight: 600;\n    font-size: 16px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  margin-bottom: 24px;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  border-color: ",
        ";\n  border-top-width: 1px;\n  border-bottom-width: 1px;\n  border-style: solid;\n  padding: 24px 0;\n  margin-bottom: 24px;\n\n  ",
        " {\n    border-top-width: 0;\n    border-bottom-width: 0;\n    padding: 0 0;\n    margin-bottom: 0;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  // border-bottom: 1px solid ",
        ";\n  display: flex;\n  flex-wrap: wrap;\n  a {\n    margin-right: 22px;\n    margin-bottom: 16px;\n  }\n  > div {\n    margin-right: 22px;\n    margin-bottom: 16px;\n  }\n  svg {\n    margin-right: 0;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  position: relative;\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: wrap;\n  .coin_detail {\n    width: ",
        ";\n    background: rgba(255, 255, 255, 0.1);\n    border-radius: 7px;\n    padding: 24px;\n    margin-bottom: 24px;\n    .coin_wrapper {\n      width: 100%;\n      position: relative;\n      display: flex;\n      justify-content: space-between;\n      flex-wrap: wrap;\n      .coin_price {\n        width: ",
        ";\n        display: flex;\n        margin-bottom: 24px;\n        .coin_img {\n          width: 38px;\n          height: 38px;\n        }\n        .price_box {\n          margin-left: 11px;\n          span {\n            color: #828282;\n            display: block;\n            margin-bottom: 2px;\n          }\n          p {\n            font-size: 16px;\n          }\n        }\n      }\n      .coin_total_list {\n        width: ",
        ";\n        .coin_list {\n          font-size: 12px;\n          margin-bottom: 16px;\n          width: 100%;\n          position: relative;\n          display: flex;\n          justify-content: space-between;\n          align-items: center;\n          p {\n            color: #828282;\n          }\n        }\n      }\n      .coin_buy {\n        position: ",
        ";\n        right: ",
        ";\n        margin-top: ",
        ";\n        display: flex;\n        align-items: center;\n        .add_coin {\n          width: 40px;\n          height: 40px;\n          background: rgba(255, 255, 255, 0.1);\n          border-radius: 50%;\n          display: flex;\n          justify-content: center;\n          align-items: center;\n          margin-right: 12px;\n          cursor: pointer;\n          transition: all 0.3s ease;\n          &:hover {\n            opacity: 0.6;\n          }\n          img {\n            width: 24px;\n            display: block;\n          }\n        }\n      }\n    }\n  }\n  .relate_detail {\n    width: ",
        ";\n    display: flex;\n    flex-wrap: wrap;\n    align-items: baseline;\n    .about_box {\n      display: flex;\n      justify-content: space-between;\n      width: ",
        ";\n      font-size: 12px;\n      padding-right: ",
        ";\n      ul {\n        margin-bottom: 0;\n      }\n    }\n    .communlty_box {\n      width: ",
        ";\n      display: flex;\n      justify-content: space-between;\n      flex-wrap: wrap;\n      h2 {\n        margin-bottom: 16px;\n        width: 100%;\n        position: relative;\n        font-size: 16px;\n        line-height: 18px;\n        color: ",
        ";\n      }\n      .communlty_icon {\n        width: ",
        ";\n      }\n      .communlty_txt {\n        width: ",
        ";\n        display: flex;\n        justify-content: space-between;\n        flex-wrap: wrap;\n        .txt_box {\n          width: ",
        ";\n          position: relative;\n          border-radius: 7px;\n          background: rgba(255, 255, 255, 0.1);\n          margin-bottom: 16px;\n        }\n        .certik {\n          padding: 8px 16px;\n        }\n        .marketplace {\n          padding: 8px;\n          display: flex;\n          font-size: 14px;\n          align-items: center;\n          .market_box {\n            width: 36px;\n            height: 36px;\n            display: flex;\n            align-items: center;\n            justify-content: center;\n            background: linear-gradient(136.03deg, rgb(18, 99, 241) -7.36%, rgb(246, 61, 94) 131.43%);\n            border-radius: 6px;\n            margin-right: 6px;\n            img {\n              width: 19px;\n            }\n          }\n        }\n      }\n    }\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
import { useMatchBreakpoints } from "@pancakeswap/uikit";
import styled from "styled-components";
import { darkColors } from "../../theme/colors";
import { Box, Flex } from "../Box";
import SocialLinks from "./Components/SocialLinks";
export var getMedia = function(value) {
    return function() {
        var ref = useMatchBreakpoints(), isMobile = ref.isMobile, isTablet = ref.isTablet;
        return isMobile ? value[0] : isTablet ? value[1] : value[2];
    };
};
export var getMedia2 = function(value) {
    return function() {
        var ref = useMatchBreakpoints(), isXl = ref.isXl, isLg = ref.isLg, isMd = ref.isMd, isXs = ref.isXs, isSm = ref.isSm, isXxl = ref.isXxl;
        var result = "";
        if (isXxl) {
            result = value[5];
        } else if (isXl) {
            result = value[4];
        } else if (isLg) {
            result = value[3];
        } else if (isMd) {
            result = value[2];
        } else if (isXs) {
            result = value[1];
        } else if (isSm) {
            result = value[0];
        }
        return result;
    };
};
export var StyledFooter = styled(Flex).withConfig({
    componentId: "sc-a476b40c-0"
})(_templateObject());
export var StyledList = styled.ul.withConfig({
    componentId: "sc-a476b40c-1"
})(_templateObject1(), function(param) {
    var theme = param.theme;
    return theme.mediaQueries.md;
});
export var StyledListItem = styled.li.withConfig({
    componentId: "sc-a476b40c-2"
})(_templateObject2(), function(param) {
    var theme = param.theme;
    return theme.colors.primary;
});
export var StyledIconMobileContainer = styled(Box).withConfig({
    componentId: "sc-a476b40c-3"
})(_templateObject3());
export var StyledToolsContainer = styled(Flex).withConfig({
    componentId: "sc-a476b40c-4"
})(_templateObject4(), darkColors.cardBorder, function(param) {
    var theme = param.theme;
    return theme.mediaQueries.sm;
});
export var StyledSocialLinks = styled(SocialLinks).withConfig({
    componentId: "sc-a476b40c-5"
})(_templateObject5(), darkColors.cardBorder);
export var StyledText = styled.span.withConfig({
    componentId: "sc-a476b40c-6"
})(_templateObject6(), darkColors.text);
export var StyledFootContent = styled.div.withConfig({
    componentId: "sc-a476b40c-7"
})(_templateObject7(), getMedia2([
    "100%",
    "100%",
    "100%",
    "50%",
    "424px",
    "424px"
]), getMedia([
    "100%",
    "100%",
    "30%"
]), getMedia([
    "100%",
    "100%",
    "55%"
]), getMedia([
    "absolute",
    "absolute",
    "relative"
]), getMedia([
    "0",
    "0",
    "auto"
]), getMedia([
    "0",
    "0",
    "24px"
]), getMedia2([
    "100%",
    "100%",
    "100%",
    "45%",
    "49%",
    "57%"
]), getMedia2([
    "100%",
    "100%",
    "100%",
    "100%",
    "100%",
    "calc(100% - 172px)"
]), getMedia([
    "0",
    "40px",
    "60px"
]), getMedia2([
    "100%",
    "100%",
    "100%",
    "100%",
    "100%",
    "172px"
]), function(param) {
    var theme = param.theme;
    return theme.colors.primary;
}, getMedia2([
    "100%",
    "100%",
    "50%",
    "100%",
    "100%",
    "100%"
]), getMedia2([
    "100%",
    "100%",
    "50%",
    "100%",
    "100%",
    "100%"
]), getMedia2([
    "48%",
    "48%",
    "48%",
    "48%",
    "48%",
    "100%"
]));
